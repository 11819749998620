export const API_REQUEST = 'API_REQUEST';
export const API_SUCCESS = 'API_SUCCESS';
export const API_ERROR = 'API_ERROR';


export const apiRequest = ({params, method, url, contentType, timeout, entity, notify, extraParams, isLog}) => ({
    type: `${entity} ${API_REQUEST}`,
    payload: params,
    meta: {method, url, contentType, timeout, entity, notify, extraParams, isLog}
  });

export const apiSuccess = ({data, params, entity, method, notify, isLog = false}) => ({
    type: `${entity} ${API_SUCCESS}`,
    payload: {data, params, method},
    meta: {entity, log: isLog, notify, apiEnd: true}
});


export const apiError = ({error, params, entity, method, errorData, notify, extraParams, isLog = true}) => ({
    type: `${entity} ${API_ERROR}`,
    payload: {error, params, method, errorData},
    meta: {entity, log: isLog, notify, extraParams, apiEnd: true}
});

export const callLocation = (location) => ({
    type: `Call Location ${API_REQUEST}`,
    payload: {},
    meta: {
        apiCall: {method: 'GET', url: location, entity: "CallEntity"},
    }
})
