import Paths from "src/components/PageConfig/Paths";
import { Crumb } from "src/features/breadcrumbs/breadcrumbSlice";

export const DEFAULT_BREADCRUMBS_MAPPING: Record<string, Crumb[]> = {
    [Paths.REPORT_BUILDER]: [
        { text: 'Home', href: `#${Paths.HOME}` },
        { text: 'Build report', href: `#${Paths.REPORT_BUILDER}` }
    ],
    [Paths.REPORT_BUILDER_WORKFLOW]: [
        { text: 'Home', href: `#${Paths.HOME}` },
        { text: 'Build report', href: `#${Paths.REPORT_BUILDER_WORKFLOW}` }
    ],
    [Paths.REPORT_BUILDER_REPORT]: [
        { text: 'Home', href: `#${Paths.HOME}` },
        { text: 'Published Reports', href: `#${Paths.REFERENCEREPORTS}` }
    ]
    // TODO: Add more per route and change default to Home breadcrumb after we move all routes here
};