import { BreadcrumbGroup } from "@amzn/awsui-components-react";
import React from "react";
import { useSelector } from "react-redux";
import { selectBreadcrumbs } from "src/features/breadcrumbs/breadcrumbSlice";
import { useDefaultBreadcrumbs } from "src/features/breadcrumbs/hooks/useDefaultBreadcrumbs";


const Breadcrumb = () => {
    const items = useSelector(selectBreadcrumbs);

    useDefaultBreadcrumbs();

    return (
        <BreadcrumbGroup
            items={items}
            ariaLabel={"Breadcrumbs"}
        />
    )
}

export default Breadcrumb
