import {ReduxAction} from "src/common/interfaces";
import {Roles} from "src/common/roles";
import {EmployeeInformation} from "src/reducers/user.reducer";

import {API_PATH} from "../constants/api";


// Common constants
export const USER_STATE = "[USER_STATE]";
export const USER_ROLES = '[USER ROLES]';
export const EMPLOYEE_INFORMATION = '[EMPLOYEE INFORMATION]'

// Types for reducer
export const RESET_USER_ROLE = `${USER_STATE} RESET ROLE`;
export const SET_ALIAS = `${USER_STATE} SET ALIAS`;
export const SET_USER_ROLES = `${USER_STATE} SET ROLES`;
export const SET_ASSUMED_ROLE = `${USER_STATE} SET ROLE`;
export const ADD_EMPLOYEE_INFORMATION = `${EMPLOYEE_INFORMATION} ADD INFORMATION`

// API call types
export const GET_USER_ROLES = `${USER_ROLES} GET`;
export const GET_EMPLOYEE_INFORMATION = `${EMPLOYEE_INFORMATION} GET`;

// Interface definition for User action
export interface UserAction extends ReduxAction {
    payload: {
        alias?: string,
        availableRoles?: Roles[],
        assumedRole?: Roles,
        employeeInformation?: EmployeeInformation
    }
}


/************************************ REDUCER ACTIONS *****************************************/
export const setAlias = (alias?: string): UserAction => ({
    type: SET_ALIAS,
    payload: {alias},
});

// Action to set available roles
export const setUserRoles = (availableRoles: Roles[]): UserAction => ({
    type: SET_USER_ROLES,
    payload: {availableRoles}
});

// Action to set assumed role
export const setUserAssumedRole = (role: Roles): UserAction => ({
    type: SET_ASSUMED_ROLE,
    payload: {assumedRole: role}
});

export const addEmployeeInformation = (employeeInformation: EmployeeInformation): UserAction => ({
    type: ADD_EMPLOYEE_INFORMATION,
    payload: {employeeInformation}
})


/************************************ API CALL ACTIONS *****************************************/
// Action to get available roles for given user
export const getUserRoles = () => ({
    type: GET_USER_ROLES,
    payload: {},
    meta:{apiCall: {method: 'GET', url: API_PATH.USER_ROLES, entity: USER_ROLES}}
})

export const getEmployeeInformation = (alias: string) => ({
    type: GET_EMPLOYEE_INFORMATION,
    payload: {alias},
    meta:{apiCall: {method: 'GET', url: API_PATH.EMPLOYEE_INFORMATION, entity: EMPLOYEE_INFORMATION}}
})
