import {createSelector} from "reselect";
import {Roles} from "src/common/roles";
import {AppState} from "src/reducers/AppState";

import {
    ADD_EMPLOYEE_INFORMATION,
    SET_ALIAS,
    SET_ASSUMED_ROLE,
    SET_USER_ROLES,
    UserAction
} from "../actions/user.actions";

export interface  EmployeeInformation {
    [alias: string]: {
        ManagerLogin: string
        EmployeeName: string
    }
}

export interface UserState {
    alias: string,
    availableRoles: Roles[],
    assumedRole: Roles | null,
    employeeInformation: EmployeeInformation
}

const initialState : UserState = {
    availableRoles: [],
    alias: '',
    employeeInformation: {},
    assumedRole: null
};


export const userReducer = (userState= initialState, {type, payload} : UserAction): UserState => {
    if(payload) {
        const {alias, availableRoles = [], assumedRole, employeeInformation = {}} = payload;
        switch (type) {
            case SET_ALIAS:
                return {...userState, alias: alias ?? ''}
            case SET_ASSUMED_ROLE:
                return {...userState, assumedRole: assumedRole ?? null}
            case SET_USER_ROLES:
                return {...userState, availableRoles}
            case ADD_EMPLOYEE_INFORMATION:
                return {...userState, employeeInformation: {...userState.employeeInformation, ...employeeInformation}}
            default:
                return userState;
        }
    }
    return userState;
}


export const getUser = (state: AppState) => state.user.alias;
export const getAvailableRoles = (state: AppState) => state.user.availableRoles;
export const getAssumedRole = (state: AppState) => state.user.assumedRole;
export const getEmployeeInformation = (state: AppState) => state.user.employeeInformation;
export const forEmployeeInformation = createSelector(
    [
        getEmployeeInformation,
        (state: AppState, alias: string) => (alias)
    ],
    (employeeInformation, alias) => {
        return employeeInformation[alias] || {ManagerLogin: "", EmployeeName: ""}
    }
)
