import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { matchPath,useLocation } from "react-router-dom";
import { setBreadcrumb } from "src/features/breadcrumbs/breadcrumbSlice";
import { DEFAULT_BREADCRUMBS_MAPPING } from "src/features/breadcrumbs/constants/defaultBreadcrumbs";


export const useDefaultBreadcrumbs = () => {
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        const currentPath = location.pathname;
        const matchingPattern = Object.keys(DEFAULT_BREADCRUMBS_MAPPING).find(path =>
            matchPath({ path, end: true }, currentPath)
        );

        if (!matchingPattern) {
            dispatch(setBreadcrumb([]));
            return;
        }

        const match = matchPath({ path: matchingPattern, end: true }, currentPath);
        const params = match?.params || {};

        const baseBreadcrumbs = DEFAULT_BREADCRUMBS_MAPPING[matchingPattern];
        
        const breadcrumbs = baseBreadcrumbs.map(crumb => ({
            ...crumb,
            href: Object.entries(params).reduce(
                (href, [key, value]) => href.replace(`:${key}`, value || ''),
                crumb.href
            )
        }));

        dispatch(setBreadcrumb(breadcrumbs));
    }, [location.pathname]);
};
