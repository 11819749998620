import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    appendBreadcrumb,
    Crumb,
    removeBreadcrumb,
    resetBreadcrumbs,
    selectBreadcrumbs,
    setBreadcrumb
} from 'src/features/breadcrumbs/breadcrumbSlice';

export const useReportBuilderBreadcrumbs = () => {
    const dispatch = useDispatch();
    const breadcrumbs = useSelector(selectBreadcrumbs);

    const handleSetBreadcrumbs = useCallback((crumbs: Crumb[]) => {
        dispatch(setBreadcrumb(crumbs));
    }, [dispatch]);

    const handleAppendBreadcrumb = useCallback((crumb: Crumb) => {
        dispatch(appendBreadcrumb(crumb));
    }, [dispatch]);

    const handleResetBreadcrumbs = useCallback(() => {
        dispatch(resetBreadcrumbs());
    }, [dispatch]);

    const handleRemoveBreadcrumb = useCallback(() => {
        dispatch(removeBreadcrumb());
    }, [dispatch]);

    return {
        breadcrumbs,
        setBreadcrumbs: handleSetBreadcrumbs,
        appendBreadcrumb: handleAppendBreadcrumb,
        resetBreadcrumbs: handleResetBreadcrumbs,
        removeBreadcrumb: handleRemoveBreadcrumb,
    };
};
