import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "src/reducers/AppState";

export interface Crumb {
    text: string,
    href: string
}

export interface BreadcrumbState {
    crumbs: Crumb []
}

const initialState: BreadcrumbState = {crumbs: []}

const breadcrumbSlice = createSlice({
    name: 'breadcrumb',
    initialState,
    reducers: {
        setBreadcrumb(breadcrumbState, {payload}: PayloadAction<Crumb[]>) {
            breadcrumbState.crumbs = payload;
        },
        appendBreadcrumb(breadcrumbState, {payload}: PayloadAction<Crumb>) {
            breadcrumbState.crumbs.push(payload);
        },
        removeBreadcrumb(breadcrumbState) {
            breadcrumbState.crumbs.pop();
        },
        resetBreadcrumbs(breadcrumbState) {
            breadcrumbState.crumbs = [];
        },
    }
});


export default breadcrumbSlice.reducer
// Export actions
export const {
    setBreadcrumb,
    appendBreadcrumb,
    removeBreadcrumb,
    resetBreadcrumbs
} = breadcrumbSlice.actions;
// Export selectors
export const selectBreadcrumbs = (state: AppState) => state.breadcrumb.crumbs;
