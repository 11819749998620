import axios from "axios";
import {API_REQUEST, apiError, apiSuccess} from "src/actions/api.actions";

export const textContentType = 'text/plain';
export const jsonContentType = 'application/json';
export const formContentType = 'multipart/form-data';

export const apiMiddleware = ({dispatch, getState}) => next => action => {
    next(action);

    if(action.type.includes(API_REQUEST)){
        // Always include role in request as this is how we determine if the API call is possible for the user
        const role = getState().user.assumedRole;
        const params = Object.assign({}, {...action.payload, role});
        const frozenParams = Object.freeze({...action.payload, role});
        const {method, url, contentType = jsonContentType, entity="DefaultEntity", notify=false, isLog=false, extraParams = {}} = action.meta;
        const data = params;
        const obj = {method, url, data, params, contentType};

        if(method === 'POST' || method === 'PATCH')
            obj["params"] = null;

        axios(obj)
            .then(response => {
                dispatch(apiSuccess({
                    data: response.data,
                    params: frozenParams,
                    entity,
                    method,
                    notify,
                    isLog
                }));
            }).catch(error => {
                dispatch(apiError({
                    error,
                    params: frozenParams,
                    entity,
                    method,
                    errorData: error.response && error.response.data,
                    notify,
                    extraParams,
                    isLog,
                }));
                console.log("Error: ", error.response);
            })
    }
};