import {
    Alert,
    Box,
    Button,
    Header,
    Modal,
    SpaceBetween,
} from "@amzn/awsui-components-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {patchBridge, resetBridgingPageStatus} from "src/actions/bridgingCommentary.actions";
import {BridgeInfo} from "src/components/BridgingCommentary/modalComponents/BridgeInfo";
import {UpdateDatesModalProps} from "src/components/BridgingCommentary/modalComponents/interfaces";
import {TimeWidget} from "src/components/BridgingCommentary/modalComponents/TimeWidget";
import {InfoList} from "src/components/FillOutBridgePage/InputComponents";
import {B} from "src/components/FillOutBridgePage/TableSubComponents";
import {updateBridge} from "src/features/reportBridges/reportBridgeSlice";
import {getBridgeApiLoading} from "src/reducers/apiLoading.reducer";
import {getBridgingPageStatus} from "src/reducers/bridgingCommentary.reducer";
import {
    addDaysToTimeStamp,
    epochToDate,
    epochToHhMm,
    humanizeEpoch, validateDueDate
} from "src/utils/timeHelpers";

interface TimeProps {
    timeOption : string,
    setTimeOption : React.Dispatch<React.SetStateAction<string>>,
    inputDays : string,
    setInputDays : React.Dispatch<React.SetStateAction<string>>
}
const UpdateDatesModal = (props: UpdateDatesModalProps) => {
    const dispatch = useDispatch();
    const {visible, setVisible,
        setUpdatingBridges, primaryBridge, setPrimaryBridge} = props;

    const bridgeApiLoading = useSelector(getBridgeApiLoading);
    // Get api call status for current bridge
    const bridgeStatus = useSelector(getBridgingPageStatus).bridge[primaryBridge.bridge_id];
    const {saved, error} = bridgeStatus || {saved: false, error: ""};
    //default added time is 7 days, default action is change by amount of days
    const [timeOption, setTimeOption] = useState("first");
    const [inputDays, setInputDays] =useState("7");
    const timeProps : TimeProps= {
        timeOption : timeOption,
        setTimeOption : setTimeOption,
        inputDays : inputDays,
        setInputDays : setInputDays
    }

    useEffect(() => {
        if(!bridgeApiLoading && saved) onModalDismiss();
    }, [bridgeApiLoading, saved]);

    // Event handler when 'X' button is clicked
    const onModalDismiss = () => {
        setUpdatingBridges(null);
        setPrimaryBridge(null);
        setVisible(false);
        dispatch(resetBridgingPageStatus());
    }
    return (
        <Modal
            onDismiss={() => onModalDismiss()}
            visible={visible}
            closeAriaLabel="Close modal"
            footer={
                <ModalFooter{...{bridgeProps :props, timeProps : timeProps}}/>
            }
            header={<Header variant="h1"> Bridge Details </Header>}
        >
            <SpaceBetween direction="vertical" size="m">
                {!!error && <Alert type='error'>{error}</Alert>}
                <ModalInfoList{...{bridgeProps :props, timeProps : timeProps}}/>
            </SpaceBetween>
        </Modal>
    )
}

const ModalInfoList = ({bridgeProps, timeProps} : {bridgeProps: UpdateDatesModalProps, timeProps : TimeProps}) =>{
    const {primaryBridge, setPrimaryBridge, userRole} = bridgeProps;
    const {timeOption, setTimeOption, inputDays, setInputDays} = timeProps;
    return (
        <InfoList fontWeight="350" margin="0">
            <BridgeInfo bridgeDetails={primaryBridge} userRole={userRole}/>
            <li className="spaced-line">
                <p style={{margin: "0", padding: "0"}}>
                    <B fontWeight={"bold"}>Due by date </B>
                </p>
                {humanizeEpoch({epoch : primaryBridge.due_timestamp, asDate : true})}
            </li>
            <li>
                <TimeWidget bridgeDetails={primaryBridge} timeOption={timeOption}
                            setTimeOption={setTimeOption} inputDays={inputDays} setInputDays={setInputDays} setBridgeDetails={setPrimaryBridge}/>
            </li>
        </InfoList>
    )
}
const updateDueDate = (props : UpdateDatesModalProps) =>{
    const {updatingBridges, setUpdatingBridges,primaryBridge} = props;
    const newBridges = [...updatingBridges];
    newBridges.forEach(
        bridge => {
            bridge.due_timestamp = primaryBridge.due_timestamp;
        }
    )
    setUpdatingBridges(newBridges);
}

const ModalFooter = ({bridgeProps, timeProps} : {bridgeProps: UpdateDatesModalProps, timeProps : TimeProps}) =>{
    // Constants
    const {primaryBridge, updatingBridges, userRole} = bridgeProps;
    const {timeOption, inputDays} = timeProps;
    const newTimestamp = (timeOption == "first")? addDaysToTimeStamp(primaryBridge.due_timestamp, Number(inputDays))
        : primaryBridge.due_timestamp;
    const dueDate = epochToDate(newTimestamp);
    const dueTime = epochToHhMm(newTimestamp);
    const bridgeApiLoading = useSelector(getBridgeApiLoading);
    const report_id = primaryBridge.report_id

    const validDueDate = validateDueDate(dueDate, dueTime);
    const dispatch = useDispatch();
    return (<Box float="right">
        <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary"
                    onClick={() => {
                        primaryBridge.due_timestamp = newTimestamp;
                        updateDueDate(bridgeProps)
                        updatingBridges.forEach(
                            bridgeDetails =>{
                                dispatch(updateBridge({report_id: report_id,  bridgeItem : bridgeDetails}))
                                dispatch(patchBridge(bridgeDetails.bridge_id,
                                    {bridge_owners: bridgeDetails.bridge_owners, 
                                    due_timestamp:bridgeDetails.due_timestamp}, userRole));
                            }
                        )
                    }}
                    disabled={ primaryBridge.bridge_owners.length === 0
                        || primaryBridge.bridge_owners.length > 20
                        || !validDueDate
                    }
                    loading={bridgeApiLoading}
            >
                Submit
            </Button>
        </SpaceBetween>
    </Box>)
}
export default UpdateDatesModal;



