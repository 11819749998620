import {Middleware} from "redux";
import {
    addEmployeeInformation,
    EMPLOYEE_INFORMATION, getEmployeeInformation,
    setAlias,
    setUserRoles,
    USER_ROLES
} from "src/actions/user.actions";
import {AppState as State} from "src/reducers/AppState";
import {EmployeeInformation} from "src/reducers/user.reducer";

export const userMiddleware: Middleware<{}, State> = ({dispatch}) => next => action => {
    next(action);

    if(action.payload) {
        const {data = {}, errorData = {}, params = {}, method = ''} = action.payload;

        switch (action.type) {
            /*********************************** User Roles API ***********************************/
            case `${USER_ROLES} API_SUCCESS`:
                dispatch(setUserRoles(data.user_roles));
                dispatch(setAlias(data.login));
                dispatch(getEmployeeInformation(data.login))
                break;
            case `${USER_ROLES} API_ERROR`:
                console.log({errorData, method, params});
                break;
            case `${EMPLOYEE_INFORMATION} API_SUCCESS`:
                const employeeInformation: EmployeeInformation = data.employee_info || {}
                dispatch(addEmployeeInformation(employeeInformation))
                break;
            case `${EMPLOYEE_INFORMATION} API_ERROR`:
                console.log({errorData, method, params});
                // Do nothing - we will fall back to alias in the case of a failure
                break;
        }
    }
}