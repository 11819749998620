import {MultiselectProps} from "@amzn/awsui-components-react";
import {ExternalReportState, ReportDefinition, ReportState, StageStatus} from "src/common/report";
import {Column, ColumnType} from "src/components/CreateReportPage/interfaces";

const monthToNumMap = new Map<string, number>([
    ["Jan", 1],
    ["Feb", 2],
    ["Mar", 3],
    ["Apr", 4],
    ["May", 5],
    ["Jun", 6],
    ["Jul", 7],
    ["Aug", 8],
    ["Sep", 9],
    ["Oct", 10],
    ["Nov", 11],
    ["Dec", 12],
    ["Q1", 13],
    ["Q2", 14],
    ["Q3", 15],
    ["Q4", 16],
    ["FY", 17],
]);


export function getInitialStageStatus(): StageStatus {
    return {
        name_and_type: {is_ready: true, is_complete: false},
        jobs_selection: {is_ready: false, is_complete: false},
        configure_columns: {is_ready: false, is_complete: false},
        stylesheet: {is_ready: false, is_complete: false}
    }
}


export function combineReportDefinitionLists(definitionsList1: ReportDefinition[], definitionsList2: ReportDefinition[]) {
    const combinedDefinitionsList: ReportDefinition[] = [];
    const seenReportIds = new Set<string>();

    definitionsList1.forEach(definition => {
        seenReportIds.add(definition.report_id);
        combinedDefinitionsList.push({...definition});
    });
    definitionsList2.forEach(definition => {
        if (!seenReportIds.has(definition.report_id)) {
            combinedDefinitionsList.push({...definition});
        }
        seenReportIds.add(definition.report_id);
    });
    return combinedDefinitionsList
}


/**
 * Function to filter out unnecessary keys from reportState obj so that API calls can get fixed size data
 * @param reportState
 */
export function filterReportState(reportState: ReportState): ReportState {
    return {
        report_id: reportState.report_id,
        report_status: reportState.report_status,
        report_library: reportState.report_library,
        report_role: reportState.report_role,
        created_at: reportState.created_at,
        created_by: reportState.created_by,
        last_modified: reportState.last_modified,
        last_modified_by: reportState.last_modified_by,
        table_last_synced: reportState.table_last_synced,
        name_and_type: reportState.name_and_type,
        span_titles: reportState.span_titles,
        stage_status: reportState.stage_status,
        jobs_selection: reportState.jobs_selection,
        configure_columns: reportState.configure_columns,
        stylesheet: reportState.stylesheet,
    }
}

/**
 * Function to filter out unnecessary keys from external report state obj so that API calls can get fixed size data
 * @param reportState
 */
export function filterExternalReportState(reportState: ExternalReportState): ExternalReportState {
    return {
        report_id: reportState.report_id,
        report_status: reportState.report_status,
        report_library: reportState.report_library,
        report_role: reportState.report_role,
        created_at: reportState.created_at,
        created_by: reportState.created_by,
        last_modified: reportState.last_modified,
        last_modified_by: reportState.last_modified_by,
        name_and_type: reportState.name_and_type,
        link: reportState.link,
        external_report_source: reportState.external_report_source,
    }
}


/**
 * Function to generate uppercase alphabet letter given ascii offset (index). It can generate alphabets ranging from
 * `A` to `ZZ`
 * @param index index number of alphabet 0 -> A, 1 -> B,..., 26 -> AA, ...
 * @return      alphabet char corresponding to given index
 */
export function generateAlphabet(index: number) {
    const firstAlphabetCode = 65;  // ascii for 'A'
    const multiplier = Math.floor(index / 26);
    const remainder = index % 26;
    const prefix = multiplier > 1 ? String.fromCharCode(firstAlphabetCode + (multiplier - 1)) : '';
    const suffix = String.fromCharCode(firstAlphabetCode + remainder);
    return `${prefix}${suffix}`;
}

/**
 * Function to generate a json using configure columns that generates a mapping for FixedType columns selected items ->
 * column name
 * @param columns of reportDefinition state
 * @return      corresponding json for FixedType cols
 * */
export function attrToColNameMap(columns: Column[] | undefined) {
    const attrToColName: { [p: string]: string } = {}
    columns?.forEach(item => {
        if (item.column_type === ColumnType.FixedTotal) {
            const key = item.scenario + "," + item.period?.map(p => p.label).join(",") + "," + item.year
            attrToColName[key] = item.column_name
        }
    })
    return attrToColName
}

export function sortPeriod(period: MultiselectProps.Options | undefined) {
    return period?.map(period => {
        return period
    }).sort(function (p1, p2) {
        if (p1.label && p2.label) {
            return monthToNumMap.get(p1.label)! - monthToNumMap.get(p2.label)!
        }
        return 0
    })
}

export const generateReportId = (item: ReportDefinition): string => {
    if (!item.workflow_id || !item.execution_id) {
        return item.report_id;
    }
    return `${item.workflow_id}_${item.execution_id}`;
};
