import {ReportBuilderAppShell, reportBuilderStore} from "@amzn/fin-press-report-builder-ui";
import React from "react";
import {Provider, useSelector} from "react-redux";
import {Outlet, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Roles} from "src/common/roles";
import {useReportBuilderBreadcrumbs} from "src/features/breadcrumbs/hooks/useReportBuilderBreadcrumbs";
import {getAssumedRole, getUser} from "src/reducers/user.reducer";


export const ReportBuilderScreen = () => {
    const navigate = useNavigate();

    const role = useSelector(getAssumedRole);
    const user = useSelector(getUser);

    const {workflowId}= useParams();
    const [searchParams, _] = useSearchParams();

    const versionId = searchParams.get('versionId');
    const executionId = searchParams.get('executionId');

    const breadcrumbContext = useReportBuilderBreadcrumbs();

    return (
        <Provider store={reportBuilderStore}>
            <ReportBuilderAppShell
                role={role || Roles.UNKNOWN}
                user={user}
                workflowId={workflowId}
                workflowExecutionId={executionId || undefined}
                workflowVersion={versionId || undefined}
                navigate={navigate}
                breadcrumbContext={breadcrumbContext}
            >
                <Outlet/>
            </ReportBuilderAppShell>
        </Provider>
    )
}
